import { render, staticRenderFns } from "./CookiePopup.vue?vue&type=template&id=6f44692a&"
import script from "./CookiePopup.vue?vue&type=script&lang=ts&"
export * from "./CookiePopup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/base/Icon.vue').default,BaseButton: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/base/Button.vue').default,BaseCheckbox: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/base/Checkbox.vue').default,BaseModalLayout: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/base/ModalLayout.vue').default,BaseModal: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/15/a/NuxtApp/components/base/Modal.vue').default})
